
import { baseApi } from './baseClient';
import { getAuthUser } from '@utils/index';

const URL_ADD_CAMPAIGN_API = '/user/add-campaign';
const URL_UPDATE_CAMPAIGN_API = '/user/update-campaign';
const URL_UPLOAD = '/get-upload-url';
const URL_GET_CAMPAIGNS = '/user/campaigns';
const URL_GET_DETAIL_CAMPAIGN = '/user/detail-campaign';
const URL_ADD_PRODUCT = '/user/add-product';
const URL_UPDATE_PRODUCT = '/user/update-products';
const URL_DELETE_PRODUCT = '/user/product';
const URL_UPDATE_SORTING_PRODUCT = '/user/sort-products';
const URL_CONNECT_ECOMMERCE_PLATFORM='/user/product-info-e-commerce'
const URL_AUTO_UPDATE_DATA_FROM_ECOMMERCE='/auto/scrape/feed'
const URL_AUTO_UPDATE_PRICE_FROM_ECOMMERCE='/auto/scrape/manually'
const URL_PROXY_IMAGE='/proxy/img'
export const addCampaignAPI = async (body: any) => {
  return await baseApi(getAuthUser().api).post(URL_ADD_CAMPAIGN_API, body)
}

export const updateCampaignAPI = async (body: any) => {
  return await baseApi(getAuthUser().api).put(URL_UPDATE_CAMPAIGN_API, body)
}

export const getURLUpload = async (body: any) => {
  return await baseApi(getAuthUser().api).post(URL_UPLOAD, body)
}

export const getDetailsCampaign = async (id: number) => {
  return await baseApi(getAuthUser().api).get(`${URL_GET_DETAIL_CAMPAIGN}?id=${id}`)
}

export const getAllCampaigns = async () => {
  return await baseApi(getAuthUser().api).get(URL_GET_CAMPAIGNS)
}

export const addProduct = async (body: any) => {
  return await baseApi(getAuthUser().api).post(URL_ADD_PRODUCT, body)
}

export const updateProducts = async (body: any) => {
  return await baseApi(getAuthUser().api).put(URL_UPDATE_PRODUCT, body)
}

export const deleteProduct = async (query: any) => {
  return await baseApi(getAuthUser().api).delete(`${URL_DELETE_PRODUCT}?id=${query.id}&campaignId=${query.campaignId}`)
}

export const dragSortingProduct = async (campaignId: number, products: any) => {
  return await baseApi(getAuthUser().api).put(`${URL_UPDATE_SORTING_PRODUCT}`, {
    id: campaignId,
    products
  })
}

export const connectECommercePlatform = async (urls: string[]) => {
  return await baseApi(getAuthUser().api).post(`${URL_CONNECT_ECOMMERCE_PLATFORM}`, {
    urls
  },{
    timeout: 1200000
  })
}

export const autoUpdateDataFromECommerce = async () => {
  return await baseApi(getAuthUser().api).get(`${URL_AUTO_UPDATE_DATA_FROM_ECOMMERCE}`,{
    timeout: 1200000
  })
}

export const autoUpdatePriceFromECommerce = async (id: any) => {
  return await baseApi(getAuthUser().api).get(`${URL_AUTO_UPDATE_PRICE_FROM_ECOMMERCE}?id=${id}`)
}

export const proxyImgByLink = async (link: string, campaignId: number) => {
  return await baseApi(getAuthUser().api).post(`${URL_PROXY_IMAGE}`,{
    link,
    campaignId
  })
}

