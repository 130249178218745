import { 
  addCampaignAPI, 
  addProduct, 
  connectECommercePlatform, 
  deleteProduct, 
  dragSortingProduct, 
  getAllCampaigns, 
  getDetailsCampaign, 
  getURLUpload, 
  updateCampaignAPI, 
  updateProducts
} from "@api/campaign";
import { AppThunk } from "..";
import { setDetailCampaign } from "./slice";
import { AxiosError } from "axios";
import { PayloadECommercePlatform } from "@src/types/api";

export const actionGetDetailCampaign = (
  campaignId: number
): AppThunk<Promise<any>> => {
  return async (dispatch) => {
    try {   
      const {data} = await getDetailsCampaign(campaignId)
      await dispatch(setDetailCampaign(data.data));
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionAddCampaign = (
  body: any
): AppThunk<Promise<number>> => {
  return async () => {
    try {
      const {data} = await addCampaignAPI(body);
      return data.data.id
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data
    }
  };
};

export const actionAddProduct = (
  body: any
): AppThunk<Promise<any>> => {
  return async () => {
    try {
      const {data} = await addProduct(body);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionUpdateProducts = (
  body: any
): AppThunk<Promise<any>> => {
  return async () => {
    try {
      const {data} = await updateProducts(body);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionUpdateCampaign = (
  body: any
): AppThunk<Promise<number>> => {
  return async () => {
    try {
      const {data} = await updateCampaignAPI(body);
      return data.data.id
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionGetUrlUpload = (
  body: any
): AppThunk<Promise<string>> => {
  return async () => {
    try {
      const {data} = await getURLUpload(body);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionGetAllCampaigns = (
): AppThunk<Promise<any>> => {
  return async () => {
    try {
      const {data} = await getAllCampaigns();
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err.response?.data;
    }
  };
};

export const actionDeleteProduct = (
  id: number,
  campaignId: number
  ): AppThunk<Promise<any>> => {
    return async () => {
      try {
        const {data} = await deleteProduct({id, campaignId});
        return data.data
      } catch (error) {
        const err = error as AxiosError
        throw err.response?.data;
      }
    };
  };


  export const actionDragSortingProducts = (
    id: number,
    products: any
    ): AppThunk<Promise<any>> => {
      return async () => {
        try {
          const {data} = await dragSortingProduct(id, products);
          return data.data
        } catch (error) {
          const err = error as AxiosError
          throw err.response?.data;
        }
      };
    };

    export const actionGetProductInfoFromECommercePlatform = (
      urls: string[]
      ): AppThunk<Promise<[PayloadECommercePlatform]>> => {
        return async () => {
          const {data} = await connectECommercePlatform(urls);
          return data.data
        };
      };