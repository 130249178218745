export default {
  allCampaigns: 'All Campaigns',
  liveCampaigns: 'Live Campaigns',
  futureCampaigns: 'Future Campaigns',
  history: 'History',
  form: {
    placeHolderSearchCampaigns: 'Search by Advertiser, Brand, Campaign Name, ...',
    dates: 'Dates',
    save: 'Save',
    editProduct: 'Edit Product',
    product: 'Product',
    description: 'Description (Optional)',
    price: 'Price',
    originalPrice: 'Original Price (Optional)',
    discount: 'Discount',
    image: 'Image',
    ctaText: 'CTA Text',
    ctaURL: 'CTA URL',
    deleteProduct: 'Delete Product',
    titleConfirmDeleteProduct: 'Are you sure you want to delete this product?',
    cancel: 'Cancel',
    delete: 'Delete',
    imageSize: 'Image Sizes (pixels)',
    width: 'Width',
    height: 'Height',
    maxProducts: 'Max active products',
    addProduct: 'Add Products',
    imagesSizeError: 'Please include image sizes before adding a product.',
    maxProductsError: `You've reached the active product limit.`,
    titleTooltipAddProduct: 'Please save the campaign first to add a product',
    status: 'Status',
    campaignJson: 'Campaign JSON',
    placeholderCampaignName: 'Enter Campaign Name',
    placeholderAdvertiser: 'Enter Advertiser',
    placeholderBrand: 'Enter Brand',
    placeholderWidth: 'Enter Width',
    placeholderHeight: 'Enter Height',
    placeholderMaxProducts: 'Enter Maximum Product Number',
    createCampaign: 'Create Campaign',
    saveChanges: 'Save Changes',
    continue: 'Continue',
    notifyNoProduct: 'There is no registered product for this campaign.',
    addProductNow: '+Add a product now',
    desDeleteProduct: `Are you sure you want to delete this product?
    This action cannot be undone. `,
    addProductMethod: 'Add Product Method',
    addManually: 'Add Manually',
    descAddManually: 'Fill in the form below to add your item.',
    connectECommercePlatform: 'Connect E-commerce Platform',
    desConnectECommercePlatform: 'Import your item from  a supported e-commerce platform.',
    verify: 'Verify',
    titleECommercePlatform:
      'Enter a link to your e-commerce platform (Shopee, Lazada, Amazon, Coupang.)',
    validationECommerceField: 'Please enter a valid URL from our partner platform.',
    connectWithLazada: 'Connect with Lazada',
    descConnectWithLazada: 'Add multiples products by connecting with your Lazada seller account.',
    addFeed: 'Add Feed',
    descAddFeed: 'Add multiple products by inputting URLS.',
    descNewManually: 'Add multiple products manually from various retail platforms.',
    labelAddFeed: 'Enter links to your e-commerce platform (Shopee, Lazada, Amazon, Coupang.)',
    add: 'Add',
    note: 'Note:',
    descNote:
      'Product images automatically resize to fit campaign sizes, edit individually if needed after adding completed.',
    lazadaURL: 'Lazada URL',
    lazadaPrice: 'Price',
    lazadaOriginalPrice: 'Original Price',
    lazadaDiscount: 'Discount',
    shopeeURL: 'Shopee URL',
    shopeePrice: 'Price',
    shopOriginalPrice: 'Original Price',
    shopeeDiscount: 'Discount'
  },
  table: {
    campaignId: 'Campaign ID',
    campaignName: 'Campaign Name',
    advertiser: 'Advertiser',
    brand: 'Brand',
    startDate: 'Start Date',
    endDate: 'End Date',
    numberOfProducts: 'Max Products',
    imageSizes: 'Size',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    actions: 'Actions',
    products: 'Products',
    newProduct: 'New Product',
    addProduct: 'Add Products',
    developerViewOnly: 'Developer view only!',
    saveCampaignFirst: 'Please save the campaign first!',
    historyCampaignViewOnly: 'Only view history!',
    newCampaign: 'New Campaign',
    continue: 'Continue'
  },
  details: {}
};
